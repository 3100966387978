<template>
    <section>
        <iHeader @back-event="closeWebview" title="明细详情"/>
        <div class="priceInfo">
            <div>费用类型：<span>{{costInnerItem.costType}}</span></div>
            <div>发票类型：<span>{{costInnerItem.invoiceType}}</span></div>
            <div>项目名称：<span>{{costInnerItem.projectName}}</span></div>
            <div>开票项目：<span>{{costInnerItem.invoiceItem}}</span></div>
            <div>数量：<span>{{costInnerItem.qty}}</span></div>
            <div class="mutationCell">含税总金额：<span>{{(costInnerItem.amount || 0) | currencyFormatter}}</span></div>
            <div>税率：<span>{{costInnerItem.taxRate}}</span></div>
            <div>成本：<span>{{((costInnerItem.amount - costInnerItem.tax) || 0) | currencyFormatter}}</span></div>
            <div>税额：<span>{{(costInnerItem.tax || 0) | currencyFormatter}}</span></div>
            <div>说明：<span>{{costInnerItem.descr || '暂无说明'}}</span></div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'costItemDetail',
        data () {
            return {
              costInnerItem: {}
            }
        },
      created() {
          this.costInnerItem = this.$SmartStorage.get('costInnerItem')
      },
      methods: {
        closeWebview () {
          this.$router.go(-1)
        },
      }
    }
</script>
<style scoped lang="scss">
    .priceInfo {
        padding: 0.2rem 0.2rem 0.2rem 0.2rem;
        background: #ffffff;
        &>div {
            display: flex;
            justify-content: space-between;
            margin-bottom: .2rem;
        }
    }
    .mutationCell {
        color: #ff4c4c;
    }
</style>
